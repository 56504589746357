import React from "react";

const RedeemGiftCheck = (props) => {
  return (
    <svg
      height="100"
      width="100"
      fill="none"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" fill="#E8F8EC" r="50" />
      <circle
        cx="50.5"
        cy="50.5"
        fill="#CFF5D8"
        r="39.5"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M38.3333 50.0001L46.6666 58.3334L63.3333 41.6667"
        stroke="#169E39"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </svg>
  );
};

export default RedeemGiftCheck;
