import React, { useEffect } from "react";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import "./Missions.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import CustomImage from "../../components/CustomImage/CustomImage";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchMissions } from "../../redux/missionSlice";
import MissionStatus from "./MissionStatus";

const Missions = () => {
  const navigate = useNavigate();
  const redeemClickHandler = (id) => {
    navigate(`/mission/${id}`);
  };
  const dispatch = useDispatch();
  const { isLoading, data: missionData } = useSelector(
    (store) => store.missions
  );
  const { user } = useSelector((store) => store.user);
  useEffect(() => {
    dispatch(fetchMissions());
  }, []);
  return (
    <div className="page mission_page">
      <Loader loading={isLoading} />
      <GoBackHeader />
      <div className="sticky_header p-20">
        <p className="title">Mission</p>
        <p className="sub_title">Earn more Rewards</p>
      </div>
      <div className="missions_cont ">
        {missionData?.map((each) => (
          <div className="mission_cont">
            <div className="image_cont">
              <CustomImage isNormal src={each?.banner_image} />
            </div>
            <div className="bottom_cont">
              <div className="flex justify-between">
                <div>
                  <p className="title">{each.mission_title || ""}</p>
                  {each?.mission_type === "affliated_points" ? (
                    <p className="sub_title">
                      Earn{" "}
                      <span className="text_red">
                        {each.enter_earn_affliated_points}
                      </span>{" "}
                      Points
                    </p>
                  ) : (
                    <p className="sub_title">
                      Win Prize{" "}
                      <span className="text_red">{" "}{each.prize_name}</span>
                    </p>
                  )}
                </div>
                <button
                  onClick={() => redeemClickHandler(each?.id)}
                  className="redeem_btn"
                >
                  Redeem
                </button>
              </div>
              <MissionStatus status={each?.mission_status} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Missions;
