import React, { useState } from "react";
import "./MyLevel.css";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import { myLevelData } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchReferralCount } from "../../redux/user/userSlice";
import LearnMoreModal from "./LearnMoreModal";

const MyLevel = () => {
  const [data, setData] = useState(myLevelData);
  const { user, referral_count } = useSelector((store) => store.user);

  useEffect(() => {
    let userReferrral_count = referral_count;
    const update = myLevelData?.map((each) => {
      let referralsNeeded = each.referrals;
      if (userReferrral_count >= referralsNeeded) {
        each.done = true;
      }
      return each;
    });
    setData(update);
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchReferralCount());
  }, []);

  const [showLearnMore, setShowLearnMore] = useState(false);

  return (
    <div className="page my_level_page">
      <LearnMoreModal show={showLearnMore} setShow={setShowLearnMore} />
      <GoBackHeader title="My Level" />
      <div className="p-20">
        <p className="section-title text-align-right ">
          Referrals : {referral_count || 0}
        </p>
        <div className="level_root">
          {data?.map((each, i) => (
            <div className={`level_cont mb-20 ${each?.done && "completed"}`}>
              <p className="level_num">{i + 1}</p>
              <div>
                <p className="title">{each?.title}</p>
                <p className="sub_tile">
                  {each?.done ? each?.completed_msg : each?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="learn_more">
          <p onClick={() => setShowLearnMore(true)}>
            Click to Learn More about Level{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MyLevel;
