import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const ProfileSettingButton = ({ title, route }) => {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate(route)} className="profile_setting_btn">
      <span className="text">{title}</span>
      <span className="icon">
        <FiChevronRight />
      </span>
    </button>
  );
};

export default ProfileSettingButton;
