import React from "react";

const Turnover = (props) => {
  return (
    <svg
      height="14"
      width="20"
      fill="none"
      viewBox="0 0 20 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5H1L5 1M5 9H19L15 13"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Turnover;
