import React from "react";

const Wallet = (props) => {
  return (
    <svg
      height="40"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.46094 8.125H32.4609C32.753 8.12487 33.0447 8.14339 33.3344 8.18047C33.2362 7.49123 32.9994 6.82901 32.6384 6.23375C32.2774 5.63848 31.7995 5.12248 31.2337 4.71688C30.6678 4.31128 30.0257 4.02447 29.346 3.87373C28.6663 3.723 27.9632 3.71148 27.2789 3.83985L6.71875 7.35H6.69531C5.40474 7.5968 4.25708 8.32698 3.48672 9.39141C4.64734 8.56588 6.03667 8.12316 7.46094 8.125ZM32.4609 10H7.46094C6.1353 10.0015 4.86437 10.5287 3.927 11.4661C2.98963 12.4034 2.46239 13.6744 2.46094 15V30C2.46239 31.3256 2.98963 32.5966 3.927 33.5339C4.86437 34.4713 6.1353 34.9986 7.46094 35H32.4609C33.7866 34.9986 35.0575 34.4713 35.9949 33.5339C36.9322 32.5966 37.4595 31.3256 37.4609 30V15C37.4595 13.6744 36.9322 12.4034 35.9949 11.4661C35.0575 10.5287 33.7866 10.0015 32.4609 10ZM28.75 25C28.2555 25 27.7722 24.8534 27.3611 24.5787C26.95 24.304 26.6295 23.9135 26.4403 23.4567C26.2511 22.9999 26.2016 22.4972 26.298 22.0123C26.3945 21.5273 26.6326 21.0819 26.9822 20.7322C27.3319 20.3826 27.7773 20.1445 28.2623 20.048C28.7472 19.9516 29.2499 20.0011 29.7067 20.1903C30.1635 20.3795 30.554 20.7 30.8287 21.1111C31.1034 21.5222 31.25 22.0056 31.25 22.5C31.25 23.163 30.9866 23.7989 30.5178 24.2678C30.0489 24.7366 29.413 25 28.75 25Z"
        fill="#F23E53"
      />
      <path
        d="M2.5 20.2734V12.5C2.5 10.807 3.4375 7.96875 6.69141 7.35391C9.45312 6.83594 12.1875 6.83594 12.1875 6.83594C12.1875 6.83594 13.9844 8.08594 12.5 8.08594C11.0156 8.08594 11.0547 10 12.5 10C13.9453 10 12.5 11.8359 12.5 11.8359L6.67969 18.4375L2.5 20.2734Z"
        fill="#F23E53"
      />
    </svg>
  );
};

export default Wallet;
