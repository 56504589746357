import React from "react";

const Copy = (props) => {
  return (
    <svg
      height="14"
      width="12"
      fill="none"
      viewBox="0 0 12 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6665 2.33332V9.33332C3.6665 9.64274 3.78942 9.93949 4.00821 10.1583C4.22701 10.3771 4.52375 10.5 4.83317 10.5H9.49984C9.80926 10.5 10.106 10.3771 10.3248 10.1583C10.5436 9.93949 10.6665 9.64274 10.6665 9.33332V4.22449C10.6665 4.06907 10.6354 3.91521 10.5751 3.77197C10.5148 3.62872 10.4265 3.49896 10.3153 3.39032L8.38159 1.49916C8.16363 1.28604 7.87092 1.16669 7.56609 1.16666H4.83317C4.52375 1.16666 4.22701 1.28957 4.00821 1.50837C3.78942 1.72716 3.6665 2.0239 3.6665 2.33332V2.33332Z"
        stroke="#8E8E93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33325 10.5V11.6666C8.33325 11.9761 8.21034 12.2728 7.99154 12.4916C7.77275 12.7104 7.476 12.8333 7.16659 12.8333H2.49992C2.1905 12.8333 1.89375 12.7104 1.67496 12.4916C1.45617 12.2728 1.33325 11.9761 1.33325 11.6666V5.24998C1.33325 4.94056 1.45617 4.64381 1.67496 4.42502C1.89375 4.20623 2.1905 4.08331 2.49992 4.08331H3.66659"
        stroke="#8E8E93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Copy;
