import React from "react";

const Eye = (props) => {
  return (
    <svg
      height="18"
      width="29"
      fill="none"
      viewBox="0 0 29 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3861 8.29373C28.1342 7.95507 22.1311 0 14.4556 0C6.77987 0 0.776869 7.95507 0.525047 8.29373L0 9.00005L0.525142 9.70636C0.776963 10.045 6.77996 18 14.4556 18C22.1312 18 28.1343 10.045 28.3862 9.70636L28.9114 9.00005L28.3861 8.29373ZM14.4556 13.7403C11.8418 13.7403 9.71526 11.6138 9.71526 8.99995C9.71526 6.38599 11.8418 4.25947 14.4556 4.25947C17.0696 4.25947 19.1961 6.38599 19.1961 8.99995C19.196 11.6138 17.0695 13.7403 14.4556 13.7403Z"
        fill="#8E8E93"
      />
    </svg>
  );
};

export default Eye;
