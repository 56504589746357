import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { signInUser } from "../../redux/user/userSlice";
import { toast } from "react-toastify";
import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";
import TextField from "../../components/Input/TextField";
import coins from "../../assests/Images/coins.png";
import logo from "../../assests/Images/logo.png";
import axios from "axios";
import { useEffect } from "react";

const ResetPassword = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const {email,token} = useParams()


  const navigate = useNavigate();

    const cb = (success, res) => {
      console.log(res,'some res')
    setLoading(false);
    if (success) {
      toast.success(
        res?.data?.message || "Password Changed Successfully"
      );
      navigate("/signin");
    } else {
      if (res?.data?.errors) {
        setErrors(res?.data?.errors);
        return;
      }
      toast.error(
        res?.data?.message || res?.message || "Something went wrong, Try again"
      );
    }
  };

  const inputChangeHandler = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    if (errors?.password_confirmation && name === "password_confirmation") {
      if (values.password == value) {
        setErrors({
          ...errors,
          password_confirmation: null,
        });
      } else {
        setErrors({
          ...errors,
          password_confirmation: ["Password and Confirm password not matched"],
        });
      }
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let payload = { ...values };
    if (payload.password !== payload.password_confirmation) {
      setErrors({
        ...errors,
        password_confirmation: ["Password and Confirm password not matched"],
      });
      return;
    }
    setLoading(true);
    axios
      .post("/reset-password", payload)
      .then((res) => {
        if (res?.data?.success) {
          cb(true,res );
        } else {
          cb(false,res);
        }
      })
      .catch((e) => {
        cb(false, e?.response?.data);
      });
  };

  useEffect(() => {
    setValues({ token, email });
  }, []);

  return (
    <>
      <Loader loading={loading} />

      <div className="login-page">
        <div className="logo-cont ">
          <div onClick={() => navigate("/")} className="logo text-center">
            <img src={logo} alt="" />
          </div>
          <div className="coins text-center">
            <img src={coins} alt="" />
          </div>
          <div className="blob_1">{my_icons.blob1}</div>
          <div className="blob_2">{my_icons.blob2}</div>
        </div>
        <div className="bottom-cont">
          <form onSubmit={formSubmitHandler}>
            <TextField
              className={` ${errors?.email ? " error" : ""}`}
              name="email"
              label="Email"
              type="text"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
              disabled
            />
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.password ? " error" : ""}`}
                name="password"
                label="New Password"
                type="password"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.password ? " error" : ""}`}
                name="password_confirmation"
                label="Confirm New Password"
                type="password"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.password_confirmation?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex justify-center">
              <Link to="/signin" className="mr-5 text-light">
                Back to sign in ?
              </Link>
            </div>
            <button className="btn btn-primary">Reset Password</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
