import React from "react";
import { useNavigate } from "react-router-dom";
import CustomImage from "../../components/CustomImage/CustomImage";

const LuckyDrawCard = ({ id, image, title }) => {
  const navigate = useNavigate();
  const luckyDrawClickHandler = () => {
    navigate(`lottery/${id}`);
  };

  return (
    <div className="luckyDraw_cont">
      <CustomImage isNormal src={image} alt={title || "lucky draw Image"} />
      <button onClick={luckyDrawClickHandler} className="btn btn-primary">
        Play
      </button>
    </div>
  );
};

export default LuckyDrawCard;
