import React, { useMemo, useState } from "react";
import "./NavBar.css";
import SideNav from "../SideNav/SideNav";
import { useNavigate } from "react-router-dom";
import FillNotification from "../../assests/jsxSvgs/FillNotification";
import HamBurger from "../../assests/jsxSvgs/HamBurger";
import { useSelector } from "react-redux";
import axios from "axios";

const NavBar = () => {
  const [showSideNav, setShowSideNav] = useState(false);

  const openSideNav = () => {
    setShowSideNav(true);
  };
  const closeSideNav = () => {
    setShowSideNav(false);
  };
  const navigate = useNavigate();
  const notifyClickHandler = () => {
    setTimeout(() => {
      axios
        .get(`/notification/read-all`)
        .then((res) => {})
        .catch((e) => {});
    }, 2000);
    navigate("/notifications");
  };
  const { data: notifcations } = useSelector(
    (store) => store?.notifications || {}
  );

  const { wallet } = useSelector((store) => store?.user || {});

  const walletInfoclick = () => {
    navigate("/wallet-info");
  };
  const cashInfoclick = () => {
    navigate("/cash-info");
  };

  const notificationCount = useMemo(() => {
    let latestNotifications = notifcations?.filter((each) => !each.status);
    return latestNotifications?.length || 0;
  }, [notifcations]);

  return (
    <>
      {showSideNav && <SideNav closer={closeSideNav} />}
      <div className="sticky_top_nav">
        <nav className="root_nav_bar">
          <div onClick={openSideNav} className="icon_cont hamicon">
            <HamBurger />
          </div>

          <div className="flex align-center gap_30">
            <div className="flex align-center gap-15">
              <div onClick={walletInfoclick} className="coins_cont">
                {wallet?.total_ap || 0}  AP
              </div>
              <div onClick={cashInfoclick} className="coins_cont light">
                INR {wallet?.total_wallet || 0}
              </div>
            </div>

            <div
              onClick={notifyClickHandler}
              className="icon_cont notification_icon relative"
            >
              {notificationCount ? (
                <p className="badge">{notificationCount}</p>
              ) : null}
              <FillNotification />
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavBar;
