import React from "react";

const Speaker = (props) => {
  return (
    <svg
      height="25"
      width="24"
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8.5C18.7956 8.5 19.5587 8.81607 20.1213 9.37868C20.6839 9.94129 21 10.7044 21 11.5C21 12.2956 20.6839 13.0587 20.1213 13.6213C19.5587 14.1839 18.7956 14.5 18 14.5M10 8.5V19.5C10 19.7652 9.89464 20.0196 9.70711 20.2071C9.51957 20.3946 9.26522 20.5 9 20.5H8C7.73478 20.5 7.48043 20.3946 7.29289 20.2071C7.10536 20.0196 7 19.7652 7 19.5V14.5"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12 8.49995L16.524 4.72995C16.6555 4.62046 16.8154 4.5507 16.9851 4.52885C17.1548 4.50701 17.3271 4.53398 17.482 4.6066C17.6369 4.67922 17.7679 4.79449 17.8597 4.9389C17.9514 5.0833 18.0001 5.25087 18 5.42195V17.5779C18.0001 17.749 17.9514 17.9166 17.8597 18.061C17.7679 18.2054 17.6369 18.3207 17.482 18.3933C17.3271 18.4659 17.1548 18.4929 16.9851 18.471C16.8154 18.4492 16.6555 18.3794 16.524 18.2699L12 14.4999H4C3.73478 14.4999 3.48043 14.3946 3.29289 14.2071C3.10536 14.0195 3 13.7652 3 13.4999V9.49995C3 9.23473 3.10536 8.98038 3.29289 8.79284C3.48043 8.6053 3.73478 8.49995 4 8.49995H12Z"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Speaker;
