import Accumulation from "./Accumulation";
import AccumulationXtoken from "./AccumulationXtoken";
import AcumulationXPoint from "./AcumulationXPoint";
import BannerWallet from "./BannerWallet";
import Blob1 from "./Blob1";
import Blob2 from "./Blob2";
import Calender from "./Calender";
import Cash from "./Cash";
import ChevronDown from "./ChevronDown";
import ChevronLeft from "./ChevronLeft";
import ChevronRight from "./ChevronRight";
import Copy from "./Copy";
import Diamond from "./Diamond";
import Eye from "./Eye";
import EyeClose from "./EyeClose";
import FillNotification from "./FillNotification";
import Gift from "./Gift";
import Gift1 from "./Gift1";
import HamBurger from "./HamBurger";
import HowManyTimes from "./HowManyTimes";
import Info from "./Info";
import Link from "./Link";
import Logout from "./Logout";
import Nonotification from "./Nonotification";
import Note from "./Note";
import Pie from "./Pie";
import PromotionMission from "./PromotionMission";
import RedeemGiftCheck from "./RedeemGiftCheck";
import Reload from "./Reload";
import RoungEight from "./RoungEight";
import Settings from "./Settings";
import Share from "./Share";
import Speaker from "./Speaker";
import SportsWeekly from "./SportsWeekly";
import Star from "./Star";
import Statistics from "./Statistics";
import Statistics1 from "./Statistics1";
import Turnover from "./Turnover";
import User from "./User";
import Wallet from "./Wallet";



export const my_icons = {
    blob1: <Blob1 />,
    blob2: <Blob2 />,
    fillNotification: <FillNotification />,
    hamBurger: <HamBurger />,
    statistics: <Statistics />,
    pie: <Pie />,
    speaker: <Speaker />,
    gift: <Gift />,
    note: <Note />,
    settings: <Settings />,
    info: <Info />,
    chevronRigh: <ChevronRight />,
    logout: <Logout />,
    share: <Share />,
    chevronLeft: <ChevronLeft />,
    copy: <Copy />,
    wallet: <Wallet />,
    chevronDwon: <ChevronDown />,
    diamond: <Diamond />,
    turnover: <Turnover />,
    accumulation: <Accumulation />,
    noNotifications: <Nonotification />,
    user: <User />,
    gift1: <Gift1 />,
    link: <Link />,
    statistics1: <Statistics1 />,
    cash: <Cash />,
    bannerComingsoon: <BannerWallet />,
    roundEight: <RoungEight />,
    star: <Star />,
    RedeemGiftCheck: <RedeemGiftCheck />,
    Calendar: <Calender />,
    sportsWeekly: <SportsWeekly />,
    eyeClose: <EyeClose />,
    eye: <Eye />,
    accumulationXToken: <AccumulationXtoken />,
    AcumulationXPoint: <AcumulationXPoint />,
    Reload: <Reload />,
    howManyTimes: <HowManyTimes />,
    promotion : <PromotionMission />


}