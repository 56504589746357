import React from "react";


const User = (props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" fill="#F7F7F7" r="12" />
      <path
        d="M12 18.75C15.7279 18.75 18.75 15.7279 18.75 12C18.75 8.27208 15.7279 5.25 12 5.25C8.27208 5.25 5.25 8.27208 5.25 12C5.25 15.7279 8.27208 18.75 12 18.75Z"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 12.75C13.2426 12.75 14.25 11.7426 14.25 10.5C14.25 9.25736 13.2426 8.25 12 8.25C10.7574 8.25 9.75 9.25736 9.75 10.5C9.75 11.7426 10.7574 12.75 12 12.75Z"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.62598 17.1368C7.81161 16.5189 8.19145 15.9774 8.70916 15.5925C9.22687 15.2076 9.85486 14.9998 10.5 15H13.5C14.1459 14.9998 14.7747 15.208 15.2928 15.5938C15.8108 15.9796 16.1906 16.5223 16.3755 17.1412"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default User;
