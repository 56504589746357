import React from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalBody from "../../components/Modal/ModalBody";

const LearnMoreModal = ({ show, setShow }) => {
  const closer = () => {
    setShow(false);
  };
  return (
    <Modal show={show} closeModal={closer}>
      <ModalHeader title="Benefit of Level ?" closeModal={closer} />
      <ModalBody className=" mt-20">
        <p className="benefit_level_text">
          Some mission will require to be in certain level ranging from AP
          rewards to lucky draw for physical prizes such as iPhone, mobile
          devices & other electronics
        </p>
      </ModalBody>
    </Modal>
  );
};

export default LearnMoreModal;
