import React from "react";

const Blob2 = (props) => {
  return (
    <svg
      height="362"
      width="292"
      fill="none"
      viewBox="0 0 292 362"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M228.266 16.6346C270.882 43.9268 285.434 103.653 290.995 159.371C295.861 208.128 282.883 255.35 255.886 293.364C228.547 331.859 190.796 357.902 147.664 361.191C98.9934 364.903 44.0884 357.064 15.4036 311.706C-12.8142 267.087 3.91462 207.52 16.8941 154.409C29.2013 104.05 45.3095 53.1764 84.8897 27.3775C128.724 -1.19392 184.246 -11.5565 228.266 16.6346Z"
        fill="#E5E5E5"
      />
    </svg>
  );
};

export default Blob2;
