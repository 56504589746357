import React from "react";

const PromotionMission = (props) => {
  return (
    <svg
      height="25"
      width="24"
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10V4.5C2 4.23478 2.10536 3.98043 2.29289 3.79289C2.48043 3.60536 2.73478 3.5 3 3.5H21C21.2652 3.5 21.5196 3.60536 21.7071 3.79289C21.8946 3.98043 22 4.23478 22 4.5V10C21.337 10 20.7011 10.2634 20.2322 10.7322C19.7634 11.2011 19.5 11.837 19.5 12.5C19.5 13.163 19.7634 13.7989 20.2322 14.2678C20.7011 14.7366 21.337 15 22 15V20.5C22 20.7652 21.8946 21.0196 21.7071 21.2071C21.5196 21.3946 21.2652 21.5 21 21.5H3C2.73478 21.5 2.48043 21.3946 2.29289 21.2071C2.10536 21.0196 2 20.7652 2 20.5V15C2.66304 15 3.29893 14.7366 3.76777 14.2678C4.23661 13.7989 4.5 13.163 4.5 12.5C4.5 11.837 4.23661 11.2011 3.76777 10.7322C3.29893 10.2634 2.66304 10 2 10ZM14 5.5H4V8.468C4.75121 8.8403 5.38347 9.41505 5.8255 10.1275C6.26754 10.8399 6.50176 11.6616 6.50176 12.5C6.50176 13.3384 6.26754 14.1601 5.8255 14.8725C5.38347 15.585 4.75121 16.1597 4 16.532V19.5H14V5.5ZM16 5.5V19.5H20V16.532C19.2488 16.1597 18.6165 15.585 18.1745 14.8725C17.7325 14.1601 17.4982 13.3384 17.4982 12.5C17.4982 11.6616 17.7325 10.8399 18.1745 10.1275C18.6165 9.41505 19.2488 8.8403 20 8.468V5.5H16Z"
        fill="black"
      />
    </svg>
  );
};

export default PromotionMission;
