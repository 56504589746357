import React from "react";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import "./ResultRefer.css";

const ResultRefer = () => {
  return (
    <div className="page">
      <GoBackHeader title="Lottery Results" hideWallet />
      <div className=" p-20 pl-25">
        <p className="section-title">How to Check Lottery Results ?</p>
        <ol className="pl-40">
          <li className="lottery_description">
            The Results will be refer to{" "}
            <span className="red bold ">www.Lottoland dot asia</span>
          </li>
          <li className="mt-10 lottery_description">
            Once the lottery results are revealed, you can check them on the
            Lottoland website. If you happen to be a winner, the administrator
            will add the prize amount to your account. You will receive a
            notification informing you of the prize you have won. To claim your
            prize, you will need to submit your details in my winning Prize
            section clicking on claim.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default ResultRefer;
