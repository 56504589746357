import React from "react";

const Logout = (props) => {
  return (
    <svg
      height="25"
      width="24"
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8.5V6.5C14 5.96957 13.7893 5.46086 13.4142 5.08579C13.0391 4.71071 12.5304 4.5 12 4.5H5C4.46957 4.5 3.96086 4.71071 3.58579 5.08579C3.21071 5.46086 3 5.96957 3 6.5V18.5C3 19.0304 3.21071 19.5391 3.58579 19.9142C3.96086 20.2893 4.46957 20.5 5 20.5H12C12.5304 20.5 13.0391 20.2893 13.4142 19.9142C13.7893 19.5391 14 19.0304 14 18.5V16.5"
        stroke="#DF162E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M7 12.5H21L18 9.5M18 15.5L21 12.5"
        stroke="#DF162E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Logout;
