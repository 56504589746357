import React from "react";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";

const GetNumberModal = ({ data, setShow, type = "success" }) => {
  return (
    <Modal show={data?.show}>
      <ModalHeader closeModal={() => setShow(false)}></ModalHeader>
      <div
        className={`get_number_modal_content ${type != "success" && " failure"}`}
      >
        {type == "success" ? (
          <>
            <div className="ticket_cont mt-20 mb-10">
              <div className="flex justify-between  align-center">
                <p>Your Ticket No</p>
                <h2 className="ticket_no">{data?.ticket_number}</h2>
              </div>
            </div>
            <p className="description">
              You can view your ticket number in Menu
            </p>
          </>
        ) : (
          <p className="no_ap_message">
            You don’t have affliate points, need {data?.ap} Apoints.
          </p>
        )}

        <button
          onClick={() => {
            setShow(false);
          }}
          className="btn btn-primary"
        >
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default GetNumberModal;
