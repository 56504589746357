import React from "react";

const Gift1 = (props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" fill="#F7F7F7" r="12" />
      <path
        d="M18 9H6C5.58579 9 5.25 9.33579 5.25 9.75V11.25C5.25 11.6642 5.58579 12 6 12H18C18.4142 12 18.75 11.6642 18.75 11.25V9.75C18.75 9.33579 18.4142 9 18 9Z"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 9.00002V18.75M17.25 12V17.25C17.25 17.6478 17.092 18.0294 16.8107 18.3107C16.5294 18.592 16.1478 18.75 15.75 18.75H8.25C7.85218 18.75 7.47064 18.592 7.18934 18.3107C6.90804 18.0294 6.75 17.6478 6.75 17.25V12M8.625 9.00002C8.12772 9.00002 7.65081 8.80248 7.29917 8.45085C6.94754 8.09922 6.75 7.6223 6.75 7.12502C6.75 6.62774 6.94754 6.15083 7.29917 5.7992C7.65081 5.44757 8.12772 5.25002 8.625 5.25002C9.34851 5.23742 10.0575 5.58847 10.6596 6.25739C11.2616 6.92632 11.7287 7.88208 12 9.00002C12.2713 7.88208 12.7384 6.92632 13.3404 6.25739C13.9425 5.58847 14.6515 5.23742 15.375 5.25002C15.8723 5.25002 16.3492 5.44757 16.7008 5.7992C17.0525 6.15083 17.25 6.62774 17.25 7.12502C17.25 7.6223 17.0525 8.09922 16.7008 8.45085C16.3492 8.80248 15.8723 9.00002 15.375 9.00002"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Gift1;
