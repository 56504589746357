import React from "react";

const FillNotification = (props) => {
  return (
    <svg
      height="27"
      width="28"
      fill="none"
      viewBox="0 0 28 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9349 19.1634L20.539 15.7733V9.15026C20.539 5.76988 18.0371 2.9632 14.7878 2.48384V0.987821C14.7878 0.442283 14.3455 0 13.8 0C13.2545 0 12.8122 0.442283 12.8122 0.987821V2.48379C9.56293 2.96315 7.06095 5.76983 7.06095 9.15021V15.7733L4.66507 19.1634C4.20386 19.8159 4.67092 20.7213 5.4717 20.7213H22.1284C22.9274 20.7213 23.3972 19.8173 22.9349 19.1634Z"
        fill="#342914"
      />
      <path
        d="M13.8001 21.4267C12.2635 21.4267 11.0134 22.6767 11.0134 24.2133C11.0134 25.7498 12.2635 27 13.8001 27C15.3366 27 16.5867 25.7499 16.5867 24.2134C16.5867 22.6768 15.3366 21.4267 13.8001 21.4267Z"
        fill="#342914"
      />
    </svg>
  );
};

export default FillNotification;
