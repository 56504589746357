import React from "react";

const BannerWallet = (props) => {
  return (
    <svg
      height="48"
      width="52"
      fill="none"
      viewBox="0 0 52 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42 2.66667H10C5.58172 2.66667 2 6.24839 2 10.6667V37.3333C2 41.7516 5.58172 45.3333 10 45.3333H42C46.4183 45.3333 50 41.7516 50 37.3333V10.6667C50 6.24839 46.4183 2.66667 42 2.66667Z"
        fill="#F23E53"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <path
        d="M17.9998 24C20.9454 24 23.3332 21.6122 23.3332 18.6667C23.3332 15.7212 20.9454 13.3333 17.9998 13.3333C15.0543 13.3333 12.6665 15.7212 12.6665 18.6667C12.6665 21.6122 15.0543 24 17.9998 24Z"
        fill="#F23E53"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <path
        d="M33.9998 13.3333H39.3332ZM33.9998 24H39.3332ZM12.6665 34.6667H39.3332Z"
        fill="#F23E53"
      />
      <path
        d="M33.9998 13.3333H39.3332M33.9998 24H39.3332M12.6665 34.6667H39.3332"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </svg>
  );
};

export default BannerWallet;
