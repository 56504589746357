import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { signInUser } from "../../redux/user/userSlice";
import { toast } from "react-toastify";
import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";
import TextField from "../../components/Input/TextField";
import coins from "../../assests/Images/coins.png";
import logo from "../../assests/Images/logo.png";
import axios from "axios";

const ForgotPassword = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const cb = (success, res) => {
    setLoading(false);
    if (success && !res?.data?.errors) {
      toast.success(res?.data?.message || "Reset Link sent to Your Email Successfully");
      //navigate("/");
    } else {
      if (res?.data?.errors) {
        setErrors(res?.data?.errors);
        return;
      }
      toast.error(
        res?.data?.message || res?.message || "Something went wrong, Try again"
      );
    }
  };

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/forgetPassword", values)
      .then((res) => {
        if (res?.data?.success) {
          cb(true, res);
        } else {
          cb(false, res);
        }
      })
      .catch((e) => {
        cb(false, e?.response?.data);
      });
  };
  return (
    <>
      <Loader loading={loading} />

      <div className="login-page">
        <div className="logo-cont ">
          <div onClick={() => navigate("/")} className="logo text-center">
            {/* <img src={logo} alt="" /> */}
          </div>
          <div className="coins text-center">
            {/* <img src={coins} alt="" /> */}
          </div>
          <div className="blob_1">{my_icons.blob1}</div>
          <div className="blob_2">{my_icons.blob2}</div>
        </div>
        <div className="bottom-cont">
          <p className="form-heading">Forgot Password</p>
          <form onSubmit={formSubmitHandler}>
            <TextField
              className={` ${errors?.email ? " error" : ""}`}
              name="email"
              label="Email"
              type="text"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
                 {errors?.email?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            <div className="flex justify-center">
              <Link to="/signin" className="mr-5 text-light">
                Back to sign in ?
              </Link>
            </div>
            <button className="btn btn-primary">Proceed</button>
          </form>
          <div className=" sign-up-cont ">
            <div className=" p-cont flex align-center justify-center">
              <p className="mr-5 text-light">Don't Have Account?</p>
              <Link className="f-15 w-600" to="/register">
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
