
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    data: null,
    isLoading: false,
}

export const fetchNotifications = createAsyncThunk('/notification', () => {
    return axios.get('/notification').then(res => res.data)
});


export const fetchNotificationSlice = createSlice({
    name: 'notification',
    initialState,
    extraReducers: {
        [fetchNotifications.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchNotifications.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action?.payload?.data;
        },
        [fetchNotifications.rejected]: (state) => {
            state.isLoading = false;
        },
    },
})

export default fetchNotificationSlice.reducer;


