import React from "react";
import { imageBasePath, noImageData } from "../../utils/utils";
import "./CustomImage.css";

const CustomImage = (props) => {
  const isNoSrc = !props.src;
  return (
    <img
      {...props}
      src={props.isNormal ? props.src : imageBasePath + props.src}
      // src={isNoSrc ? noImageData : basepath + props.src}
      // onError={({ currentTarget }) => {
      //   currentTarget.onerror = null;
      //   currentTarget.src = noImageData;
      // }}
      alt={props.alt || ""}
      className={`${props?.className || ""}  ${isNoSrc ? "no_data" : ""}`}
    />
  );
};

export default CustomImage;
