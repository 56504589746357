import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setUserInfo } from './redux/user/userSlice';
import AuthRoutes from './Routes/AuthRoutes';
import AdminRoutes from './Routes/AdminRoutes';
import MobileWidthLayout from './components/MobileWidthLoyout/MobileWidthLayout';
import { reloadToLoginofUnauthenticated } from './utils/utils';
import { useEffect } from 'react';
import { fetchNotifications } from './redux/NotificationSlice';



function App() {
  const { user } = useSelector(data => data)
  console.log(user, 'user Details')
  let isLoggedin = user?.user;
  const dispatch = useDispatch()
  const userDetails = localStorage.getItem('userDetails')
  if (userDetails) {
    if (!isLoggedin) {
      isLoggedin = true
      dispatch(setUserInfo(JSON.parse(userDetails)))
    }
  }

  useEffect(() => {
    if (!isLoggedin) {
      let pathname = window.location.pathname;
      if (
        pathname == '/signin/' ||
        pathname == "/signin" ||
        pathname?.includes('/register') ||
        pathname?.includes('/reset') ||
        pathname?.includes('/forgot-password') ||
        pathname ==='/'

      ) return
      reloadToLoginofUnauthenticated()
    }
    else {
      dispatch(fetchNotifications());
      setTimeout(() => {
        dispatch(fetchNotifications());
      }, 60 * 1000)
    }

  }, [isLoggedin])

  return (
    <>
      <ToastContainer />
      <div className="App">
        <MobileWidthLayout >
          <Router>
            {
              isLoggedin ? <AdminRoutes /> : <AuthRoutes />
            }
          </Router>
        </MobileWidthLayout>

      </div>
    </>
  );
}

export default App;
