import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import NavBar from "../../components/NavBar/NavBar";
import "./Dashboard.css";
import LuckyDrawCard from "./LuckyDrawCard";
import { fetchAllLuckyDraws } from "../../redux/dashboard/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { fetchWallet } from "../../redux/user/userSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllLuckyDraws());
  }, []);

  const {user} = useSelector(store=>store?.user?.user||{})
  useEffect(() => {
    if (user?.id) {
      dispatch(fetchWallet())
    }
  },[user?.id])

  const { luckydraws, isLoading } = useSelector(
    (store) => store?.dashboard || {}
  );

  const navigate = useNavigate();

  return (
    <div className="dashboard-page page ">
      <Loader loading={isLoading} />
      <NavBar />
      <div className="pl-20 pt-20">
        <div className="overflow-x">
          <div className="luckydraw_scroll_cont">
            {luckydraws?.map((each) => (
              <LuckyDrawCard
                key={each?.id}
                id={each?.id}
                title={each?.game_title}
                image={each?.game_image}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="p-20 flex gap-15 small_cards_cont">
        <div
          onClick={() => navigate("/missions")}
          className="small_card flex-1"
        >
          <p className="title">Mission</p>
        </div>
        <div
          onClick={() => navigate("/daily-rewards")}
          className="small_card flex-1 daily_reward"
        >
          <p className="title">
            Daily <br /> Reward
          </p>
        </div>
      </div>
      <div className="p-20">
        <p className="section-title">Bj Lottery Lucky Draw</p>
        <p className="dashboard_description text-light">
          Get cash prize of 1,00,000+ participate user. You get lucky number now
          for free everyday. Result will be refer to <span onClick={()=>navigate('/result-refer')} className="red pointer bold">lottoland</span> 
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
