import React from "react";

const Note = (props) => {
  return (
    <svg
      height="25"
      width="24"
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.615 20.5H7C6.46957 20.5 5.96086 20.2893 5.58579 19.9142C5.21071 19.5391 5 19.0304 5 18.5V6.5C5 5.96957 5.21071 5.46086 5.58579 5.08579C5.96086 4.71071 6.46957 4.5 7 4.5H15C15.5304 4.5 16.0391 4.71071 16.4142 5.08579C16.7893 5.46086 17 5.96957 17 6.5V14.5M14 19.5L16 21.5L20 17.5M9 8.5H13M9 12.5H11"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Note;
