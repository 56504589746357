import React from "react";

const EyeClose = (props) => {
  return (
    <svg
      height="23"
      width="29"
      fill="none"
      viewBox="0 0 29 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3861 10.2937C28.1342 9.95507 22.1311 2 14.4556 2C6.77987 2 0.776869 9.95507 0.525047 10.2937L0 11L0.525142 11.7064C0.776963 12.045 6.77996 20 14.4556 20C22.1312 20 28.1343 12.045 28.3862 11.7064L28.9114 11L28.3861 10.2937ZM14.4556 15.7403C11.8418 15.7403 9.71526 13.6138 9.71526 11C9.71526 8.38599 11.8418 6.25947 14.4556 6.25947C17.0696 6.25947 19.1961 8.38599 19.1961 11C19.196 13.6138 17.0695 15.7403 14.4556 15.7403Z"
        fill="#8E8E93"
      />
      <path
        d="M14.4556 13.0135C15.5677 13.0135 16.4692 12.112 16.4692 11C16.4692 9.88791 15.5677 8.98642 14.4556 8.98642C13.3436 8.98642 12.4421 9.88791 12.4421 11C12.4421 12.112 13.3436 13.0135 14.4556 13.0135Z"
        fill="#8E8E93"
      />
      <line
        stroke="#8E8E93"
        strokeWidth="2"
        x1="25.7071"
        x2="4.70711"
        y1="0.707107"
        y2="21.7071"
      />
    </svg>
  );
};

export default EyeClose;
