import React from "react";

const RoungEight = (props) => {
  return (
    <svg
      height="16"
      width="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00008 7.99992C8.73646 7.99992 9.33341 7.40297 9.33341 6.66659C9.33341 5.93021 8.73646 5.33325 8.00008 5.33325C7.2637 5.33325 6.66675 5.93021 6.66675 6.66659C6.66675 7.40297 7.2637 7.99992 8.00008 7.99992Z"
        stroke="#E81C34"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M8.00008 10.6667C8.73646 10.6667 9.33341 10.0697 9.33341 9.33333C9.33341 8.59695 8.73646 8 8.00008 8C7.2637 8 6.66675 8.59695 6.66675 9.33333C6.66675 10.0697 7.2637 10.6667 8.00008 10.6667Z"
        stroke="#E81C34"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M8.00008 13.3333C10.9456 13.3333 13.3334 10.9455 13.3334 7.99996C13.3334 5.05444 10.9456 2.66663 8.00008 2.66663C5.05456 2.66663 2.66675 5.05444 2.66675 7.99996C2.66675 10.9455 5.05456 13.3333 8.00008 13.3333Z"
        stroke="#E81C34"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default RoungEight;
