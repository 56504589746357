import { configureStore } from '@reduxjs/toolkit'
import dashboardReducer from './dashboard/dashboardSlice'
import userReducer from './user/userSlice'
import NotificationSlice from './NotificationSlice'
import missionSlice from './missionSlice'
import myWinningsSlice from './myWinningsSlice'
import dailyRewardsSlice from './dailyRewardsSlice'
import walletSlice from './WalletSlice'



export const store = configureStore({
    reducer: {
        dashboard: dashboardReducer,
        user: userReducer,
        notifications: NotificationSlice,
        missions: missionSlice,
        winningPrize: myWinningsSlice,
        dailyRewards: dailyRewardsSlice,
        wallet: walletSlice
    },
})