import React from "react";

const HistoryCard = ({ title, date, points, isCredit , isDefault_color, className }) => {
  return (
    <div className={`affliate_card ${!isCredit&&" debit "} ${isDefault_color&&" is_default"} ${className}`}>
      <div className="bold_titles flex justify-between align-center">
        <p>{title}</p>
        <span className="cash"><p>{points}</p></span>
        
      </div>
      <div className="light_titles flex justify-between align-center">
        <p>{date}</p>
       { !isDefault_color && <p>{isCredit ? "Earning" : "Deducted"}</p>}
      </div>
    </div>
  );
};

export default HistoryCard;
