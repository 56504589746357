import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import "./SideNav.css";
import { useDispatch, useSelector } from "react-redux";
import { clearUserInfo, levelCalculator } from "../../redux/user/userSlice";
import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";
import { toast } from "react-toastify";
import { useState } from "react";
import Loader from "../Loader/Loader";
import { AiFillHome, AiFillGift } from "react-icons/ai";
import { MdWorkspacesFilled } from "react-icons/md";
import { BsCashCoin } from "react-icons/bs";
import { BiHelpCircle } from "react-icons/bi";
import { GiTwoCoins } from "react-icons/gi";
import { FaCoins } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { stringTrim } from "../../utils/utils";

const SideNav = ({ closer }) => {
  const navigate = useNavigate();
  const navitemClickHandler = (to) => {
    closer();
    navigate(to);
  };
  const { user } = useSelector((data) => data.user || {});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const userSignOut = () => {
    closer();
    dispatch(clearUserInfo());
    navigate("/signin");
  };

  const signInHandler = () => {
    setLoading(true);
    userSignOut();
  };
  const LinkCopyHandler = () => {
    let referalLink = `${origin}/register/?referalCode=${user?.user?.referal_code}`;
    navigator?.clipboard?.writeText(referalLink);
    toast.success("Referral Link copied..");
  };

  const level = useSelector(levelCalculator);
  return ReactDOM.createPortal(
    <>
      <Loader loading={loading} />
      <div className="back_drop" onClick={closer}></div>
      <div className="side_nav_root side_nav_padding">
        <div className="flex flex-column h-100 justify-between">
          <div className="content">
            <div className=" side-nav-profile flex justify-between">
              <div className="flex align-center">
                <div className="profile_img_cont">
                  {user?.user?.profile_image ? (
                    <img src={`${user?.user?.profile_image}`} alt="" />
                  ) : (
                    my_icons.user
                  )}
                </div>
                <div className="flex flex-column">
                  <p className="profile_name">
                    {stringTrim(user?.user?.name,8) || "User"} ( {level || ""} )
                  </p>
                  <Link className="profile_link" to="/profile">
                    View Profile
                  </Link>
                </div>
              </div>
              <div
                onClick={() => navitemClickHandler("/profile")}
                className="icon_cont"
              >
                {my_icons.chevronRigh}
              </div>
            </div>

            <div className="menu_items">
              <div
                onClick={() => {
                  navitemClickHandler("/");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">
                  <AiFillHome />
                </div>
                <p className="menu_text">Home</p>
              </div>
              <div
                onClick={() => {
                  navitemClickHandler("/missions");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">
                  <MdWorkspacesFilled />
                </div>
                <p className="menu_text">Missions</p>
              </div>
              <div
                onClick={() => {
                  navitemClickHandler("/daily-rewards");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">
                  <FaCoins />
                </div>
                <p className="menu_text">Daily Rewards</p>
              </div>
              <div
                onClick={() => {
                  navitemClickHandler("/my-winnings");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">
                  <AiFillGift />
                </div>
                <p className="menu_text">My Winning Prize</p>
              </div>
              <div
                onClick={() => {
                  navitemClickHandler("/cash-info");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">
                  <BsCashCoin />
                </div>
                <p className="menu_text">My Cash</p>
              </div>
              <div
                onClick={() => {
                  navitemClickHandler("/wallet-info");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">
                  <GiTwoCoins />
                </div>
                <p className="menu_text">My APoints</p>
              </div>
              <div
                onClick={() => {
                  navitemClickHandler("/my-level");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">{my_icons.statistics}</div>
                <p className="menu_text">My Level</p>
              </div>

              <div
                onClick={() => {
                  navitemClickHandler("/withdrawal-list");
                }}
                className="menu_item flex align-center"
              >
                <div className="icon_cont">
                  <GrTransaction size={24} />
                </div>
                <p className="menu_text">Withdrawal List</p>
              </div>

              <div
                onClick={() => {
                  navitemClickHandler("/how-it-works");
                }}
                className="menu_item flex align-center mb-10"
              >
                <div className="icon_cont">
                  <BiHelpCircle size="24" />
                </div>
                <p className="menu_text">How it Works ?</p>
              </div>
            </div>
          </div>
          <div>
            {user?.user && (
              <div style={{ marginBottom: "40px" }} className="share-cont ">
                <div className="referal-cont flex justify-between align-center">
                  <button onClick={LinkCopyHandler} className="referal-button">
                    Copy referral Link
                    <span className="copy-icon">{my_icons.copy}</span>
                  </button>
                  {my_icons.share}
                </div>
              </div>
            )}
            <button
              onClick={signInHandler}
              className="btn logout flex align-center  "
            >
              <div className="logout_icon icon_cont">{my_icons.logout}</div>
              {user?.user ? "Sign Out" : "Sign In"}
            </button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("side_nav")
  );
};

export default SideNav;
