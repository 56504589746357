import React from "react";
import Modal from "../../components/Modal/Modal";
import TextField from "../../components/Input/TextField";
import { useState } from "react";
import ModalHeader from "../../components/Modal/ModalHeader";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { fetchMyWinningPrizes } from "../../redux/myWinningsSlice";
import { useDispatch } from "react-redux";
import { fetchCashHistory, fetchWalletHistory } from "../../redux/WalletSlice";
import { fetchWallet } from "../../redux/user/userSlice";

const WithdrawModal = ({ show, setShow, data }) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const inputChangeHandler = (e) => {
    setValues({ ...values, [e?.target?.name]: e?.target?.value });
  };
  const dispatch = useDispatch();

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/cash-withdrawal", values)
      .then((res) => {
        setLoading(false);
        if (res?.data?.success) {
          setShow(false);
          toast.success(
            res?.data?.message || "Withdraw Submitted Successfully..."
          );
            dispatch(fetchWallet());
            dispatch(fetchCashHistory())
        } else {
          toast.error(
            res?.data?.message || "Something Went Wrong , Please try again..."
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          e?.response?.data?.message ||
            "Something Went Wrong , Please try again..."
        );
        console.log(e);
      });
  };
  return (
    <Modal className="claim_modal_root" show={show}>
      <Loader loading={loading} />
      <ModalHeader
        closeModal={() => setShow(false)}
        title="Withdraw Form"
      ></ModalHeader>
      <form className="pt-20 pb-20" onSubmit={formSubmitHandler}>
        <TextField
          className="mb-10"
          name="amount"
          label="Amount"
          required
          type="number"
          values={values}
          onChange={inputChangeHandler}
        />
        <div className="address">
          <TextField
            className="mb-10"
            name="text"
            label="Bank Account Info"
            required
            values={values}
            isTextArea
            onChange={inputChangeHandler}
          />
          <p className="hint_message">
            Enter Bank Holder Name , Account Number , Bank Name , Ifsc Code ,Country
          </p>
        </div>

        <button className="btn btn-primary">Submit</button>
      </form>
    </Modal>
  );
};

export default WithdrawModal;
