import React from "react";

const HamBurger = (props) => {
  return (
    <svg
      height="44"
      width="44"
      fill="none"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        stroke="#342914"
        strokeLinecap="round"
        strokeWidth="3"
        x1="7.5"
        x2="36.5"
        y1="14.5"
        y2="14.5"
      />
      <line
        stroke="#342914"
        strokeLinecap="round"
        strokeWidth="3"
        x1="7.5"
        x2="36.5"
        y1="22.5"
        y2="22.5"
      />
      <line
        stroke="#342914"
        strokeLinecap="round"
        strokeWidth="3"
        x1="7.5"
        x2="27.5"
        y1="30.5"
        y2="30.5"
      />
    </svg>
  );
};

export default HamBurger;
