import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    data: null,
    isLoading: false,
}

export const fetchDailyRewardsAction = createAsyncThunk('/rewardtype', (data, { rejectWithValue }) => {
    return axios.get('/rewardtype').then(res => {
        if (res?.data?.status!=200) {
            return rejectWithValue(res?.data)
        }
        return res?.data
    })
});


export const dailyRewardsSlice = createSlice({
    name: 'dailyReward',
    initialState,
    extraReducers: {
        [fetchDailyRewardsAction.pending]: (state) => {
            if(!state?.data?.length)
            state.isLoading = true;
        },
        [fetchDailyRewardsAction.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action?.payload?.data
        },
        [fetchDailyRewardsAction.rejected]: (state) => {
            state.isLoading = false;
        },
    },
})

export default dailyRewardsSlice.reducer;