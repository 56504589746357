import React from "react";

const AccumulationXtoken = (props) => {
  return (
    <svg
      height="22"
      width="20"
      fill="none"
      viewBox="0 0 20 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14C11.6569 14 13 12.6569 13 11C13 9.34315 11.6569 8 10 8C8.34315 8 7 9.34315 7 11C7 12.6569 8.34315 14 10 14Z"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M10 20V20.01M1 8V8.01M19 8V8.01M6 19.1C4.63534 18.4344 3.46126 17.4343 2.58704 16.1929C1.71281 14.9515 1.16686 13.5091 1 12M14 19.1C15.3647 18.4344 16.5387 17.4343 17.413 16.1929C18.2872 14.9515 18.8331 13.5091 19 12M4.2 4C5.80809 2.68396 7.82204 1.96491 9.9 1.96491C11.978 1.96491 13.9919 2.68396 15.6 4"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default AccumulationXtoken;
