import React from "react";

const Blob1 = (props) => {
  return (
    <svg
      height="337"
      width="424"
      fill="none"
      viewBox="0 0 424 337"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M332.492 22.1644C386.504 49.0167 417.902 97.9179 423.417 149.397C428.525 197.073 399.449 240.421 359.635 275.51C319.847 310.575 269.117 338.921 210.662 336.898C150.137 334.804 98.8069 304.438 61.8436 265.205C21.5078 222.391 -12.9379 170.417 4.76252 118.351C22.8901 65.0297 84.0789 31.0727 147.798 12.3713C208.888 -5.55817 277.035 -5.40618 332.492 22.1644Z"
        fill="#D1D1D1"
        opacity="0.4"
      />
    </svg>
  );
};

export default Blob1;
