import React from "react";
import "./MissionDetail.css";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import "./MissionDetail.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import { useEffect } from "react";
import { FaFileUpload } from "react-icons/fa";
import { stringTrim } from "../../utils/utils";
import { toast } from "react-toastify";
import MissionStatus from "../Missions/MissionStatus";
import { REDEEM_STATUS, REJECT_STATUS } from "./data";

const MissionDetail = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const inputChangeHandler = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const fetchMisssionDetails = () => {
    setLoading(true);
    axios
      .get(`mission/${id}`)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 200) {
          setData(res?.data?.data?.[0]);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  useEffect(() => {
    fetchMisssionDetails();
  }, []);

  const submitMissionHandler = () => {
    if (!file) {
      toast.error("Please Select the Proof and then submit.");
    } else {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("mission_id", id);
      formdata.append("image", file);
      if (!file?.type?.includes("image")) {
        formdata.delete("image");
        formdata.append("video", file);
      }
      axios
        .post("/mission/submit", formdata)
        .then((res) => {
          setLoading(false);
          if (res?.data?.status == 200) {
            toast.success(res?.data?.message);
            fetchMisssionDetails();
          } else {
            toast.error(
              res?.data?.message || "Something went wrong try again..."
            );
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(
            e?.response?.data?.message || "Something went wrong try again..."
          );
        });
    }
  };

  return (
    <div className="page mission_detail">
      <Loader loading={loading} />
      <GoBackHeader />
      <div className="p-20">
        <p className="section-title">{data?.mission_title || ""}</p>
        <div className="mission_image_cont">
          <img src={data?.banner_image} alt="" />
        </div>
        <div className="mission_info">
          {parse(data?.mission_description || "")}
        </div>

        {/* {data?.mission_type === "affliated_points" ? (
          <p className="sub_title">
            Earn{" "}
            <span className="text_red">{data.enter_earn_affliated_points}</span>{" "}
            Points
          </p>
        ) : (
          <p className="sub_title">
            Win Prize <span className="text_red"> {data.prize_name}</span>
          </p>
        )} */}
        {!loading && data?.mission_status != REDEEM_STATUS && (
          <div className="mission_status_cont">
            <p className="section-title mt-20">Your Mission status</p>
            <MissionStatus status={data?.mission_status} />
          </div>
        )}

        {(data?.mission_status === REDEEM_STATUS ||
          data?.mission_status === REJECT_STATUS) && (
          <div>
            <p className="red_text">
              Please Submit the Proof of Screenshot or Short Video. If you
              completed the Mission.
            </p>
            <label className="fileUpload_label" htmlFor="file">
              <span className="icon">
                <FaFileUpload />
              </span>
              <div>
                Click  to Select {file && "Another File"}
                <p className="selectedFile">{stringTrim(file?.name, 22)}</p>
              </div>
            </label>
            <input
              onChange={inputChangeHandler}
              className="inputFile_upload"
              id="file"
              type="file"
            />
            <button onClick={submitMissionHandler} className="btn btn-primary">
              {data?.mission_status === REJECT_STATUS
                ? "Re Submit Mission"
                : "Submit Mission"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionDetail;
