import React from "react";

const Statistics = (props) => {
  return (
    <svg
      height="25"
      width="24"
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12.5H4C3.44772 12.5 3 12.9477 3 13.5V19.5C3 20.0523 3.44772 20.5 4 20.5H8C8.55228 20.5 9 20.0523 9 19.5V13.5C9 12.9477 8.55228 12.5 8 12.5Z"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M14 8.5H10C9.44772 8.5 9 8.94772 9 9.5V19.5C9 20.0523 9.44772 20.5 10 20.5H14C14.5523 20.5 15 20.0523 15 19.5V9.5C15 8.94772 14.5523 8.5 14 8.5Z"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M20 4.5H16C15.4477 4.5 15 4.94772 15 5.5V19.5C15 20.0523 15.4477 20.5 16 20.5H20C20.5523 20.5 21 20.0523 21 19.5V5.5C21 4.94772 20.5523 4.5 20 4.5Z"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M4 20.5H18"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Statistics;
