import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { signInUser } from "../../redux/user/userSlice";
import "./LoginPage.css";
import { toast } from "react-toastify";
import TextField from "../../components/Input/TextField";
import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";

const LoginPage = () => {
  const [values, setValues] = useState({
    username: "test1@gmail.com",
    password: "test1@gmail.com",
  });
  const [errors, setErrors] = useState({});

  const cb = (success, res) => {
    if (success) {
      toast.success(res?.data?.message || "User Logged in Successfully");
    } else {
      if (res?.errors) {
        setErrors(res?.errors);
        return;
      }
      toast.error(
        res?.data?.message || res?.message || "Something went wrong, Try again"
      );
    }
  };

  const dispatch = useDispatch();

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(signInUser({ payload: values, cb: cb }));
  };
  const { isLoading } = useSelector((store) => store.user);

  return (
    <>
      <Loader loading={isLoading} />

      <div className="login-page">
        <div className="logo-cont ">
          <div className="blob_1"></div>
          <div className="blob_2"></div>
          <img
            src={require("../../assests/Images/bonus_logo.png")}
            alt=""
            className="bonus_logo"
          />
        </div>
        <div className="bottom-cont">
          <p className="form-heading">
            Welcome back!, let’s sign in to your account
          </p>
          <form onSubmit={formSubmitHandler}>
            <TextField
              className={` ${errors?.username ? " error" : ""}`}
              name="username"
              label="Username or Email"
              type="text"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            <TextField
              className={` ${errors?.password ? " error" : ""}`}
              name="password"
              label="Password"
              type="password"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            <div className="flex justify-center">
              <Link to="/forgot-password" className="mr-5 text-light">
                Forgot Password?
              </Link>
            </div>
            <button className="btn btn-primary">Sign in</button>
          </form>
          <div className=" sign-up-cont ">
            <div className=" p-cont flex align-center justify-center">
              <p className="mr-5 text-light">Don't Have Account?</p>
              <Link className="f-15 w-600" to={`/register`}>
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
