import React from "react";

const Pie = (props) => {
  return (
    <svg
      height="25"
      width="24"
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.69998C8.43573 4.06262 6.99694 4.83718 5.8329 5.94332C4.66886 7.04946 3.82197 8.44689 3.38005 9.99066C2.93814 11.5344 2.91731 13.1683 3.31971 14.7229C3.72212 16.2774 4.53311 17.696 5.66857 18.8314C6.80403 19.9669 8.2226 20.7779 9.77714 21.1803C11.3317 21.5827 12.9656 21.5619 14.5093 21.12C16.0531 20.678 17.4506 19.8311 18.5567 18.6671C19.6628 17.5031 20.4374 16.0643 20.8 14.5C20.8 14.2348 20.6947 13.9804 20.5071 13.7929C20.3196 13.6053 20.0652 13.5 19.8 13.5H13C12.4696 13.5 11.9609 13.2893 11.5858 12.9142C11.2107 12.5391 11 12.0304 11 11.5V4.49998C10.9876 4.38151 10.9518 4.26668 10.8946 4.16215C10.8375 4.05763 10.7602 3.96548 10.6672 3.89107C10.5741 3.81665 10.4673 3.76144 10.3527 3.72864C10.2382 3.69584 10.1183 3.6861 10 3.69998Z"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M15 4C16.2697 4.44708 17.423 5.1733 18.3748 6.12516C19.3267 7.07702 20.0529 8.23028 20.5 9.5H16C15.7348 9.5 15.4804 9.39464 15.2929 9.20711C15.1054 9.01957 15 8.76522 15 8.5V4Z"
        stroke="#342914"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Pie;
