import React, { useState } from "react";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./DailyRewards.css";
import { fetchWallet } from "../../redux/user/userSlice";
import { fetchDailyRewardsAction } from "../../redux/dailyRewardsSlice";

const DailyRewards = () => {
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useSelector((store) => store.dailyRewards || {});

  const fetchDailyRewards = (noLoad) => {
    dispatch(fetchDailyRewardsAction());
  };
  useEffect(() => {
    fetchDailyRewards();
  }, []);

  const { user } = useSelector((store) => store?.user?.user || {});
  const dispatch = useDispatch();

  const claimClickHandler = (each) => {
    let url = "/dailyRewardPoints";
    let payload = {
      user_id: user?.id,
      reward_type_id: each?.id,
    };
    setLoading(true);
    axios
      .post(url, payload)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 200) {
          fetchDailyRewards(true);
          dispatch(fetchWallet());
          toast.success(res?.data?.message || "Redeem Successfully");
        } else {
          toast.error(res?.data?.message || "Failed to redeem");
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message || "Failed to redeem");
      });
  };
  return (
    <div className="page">
      <GoBackHeader />
      <Loader loading={ loading || isLoading} />
      <div className="p-20">
        <p className="section-title">Daily Rewards</p>
        {data?.map((each) => (
          <div className="reward_card flex mb-20">
            <div className="flex align-center flex-1">
              <div className="flex-1">
                <p className="title">{each?.reward_title || ""}</p>
                <p className="points">
                  {each?.reward_points} &nbsp; <span> Apoints</span>
                </p>
              </div>
              <div className="coin_icon_cont">
                <img src={require("../../assests/v2/coin.png")} />
              </div>
            </div>

            <button
              disabled={each?.claimed}
              onClick={() => {
                claimClickHandler(each);
              }}
            >
              Claim
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyRewards;
