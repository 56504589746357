import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';
import setupAxios from './redux/setupAxios';
import { store } from './redux/store'
import { Provider } from 'react-redux';



axios.defaults.baseURL = "https://backend.bajibonus.com/api"
setupAxios(axios, store)
axios.interceptors.response.use(function (response) {
  return response;
}, function (e) {
  if (e?.response?.status === 401) {
    localStorage.removeItem('userDetails')
   // if(!e.request?.responseURL?.includes('/login'))
    // reloadToLoginofUnauthenticated()
  }
  return Promise.reject(e);
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store} >
      <App />
    </Provider>
  </React.StrictMode>
);

