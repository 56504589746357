import React, { useState } from "react";
import "./MyWinningPrize.css";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
// import { winningsdata } from "./data";
import ClaimModal from "./ClaimModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchMyWinningPrizes } from "../../redux/myWinningsSlice";
import Loader from "../../components/Loader/Loader";
import MissionStatus from "../Missions/MissionStatus";

const MyWinningPrize = () => {
  // const [myWinnings, setMyWinnings] = useState(winningsdata);
  const [claimData, setClaimData] = useState();
  const [showClaimModal, setShowClaimModal] = useState(false);

  const claimClickHandler = (id) => {
    setShowClaimModal(true);
    setClaimData(id);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMyWinningPrizes());
  }, []);
  const { data, isLoading } = useSelector((store) => store?.winningPrize);

  const buttonTextGenerate = (type) => {
    if (type == "Submit") {
      return "Claim InProgress";
    } else if (type == "Approved") {
      return "Claimed Suceessfully";
    } else if (type == "Reject") {
      return "Claim Again";
    } else return "Claim";
  };

  return (
    <div className="page my_winning">
      <Loader loading={isLoading} />
      <GoBackHeader />
      <ClaimModal
        setShow={setShowClaimModal}
        show={showClaimModal}
        data={claimData}
      />
      <div className="p-20">
        <p className="section-title">My Winning Prize</p>
        <div className="my_winnings_cont">
          {data?.map((each) => (
            <div className="winning_card">
              <div className="image_cont">
                <img src={each?.prize_image} alt="" />
              </div>
              <div className="bottom_cont">
                <p className="title">{each?.prize_name}</p>
                {each.status == "Reject" ? (
                  <MissionStatus
                    className={"Reject"}
                    message="Your Request Rejected"
                  />
                ) : null}
                <button
                  onClick={() => {
                    claimClickHandler(each);
                  }}
                  className={`btn btn-primary ${each?.status}`}
                  disabled={each?.status != "Claim" && each?.status != "Reject"}
                >
                  {buttonTextGenerate(each?.status)}
                </button>
              </div>
            </div>
          ))}
          {!isLoading && !data?.length && (
            <p className="no_data">You Don't have any Winning Prizes</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyWinningPrize;
