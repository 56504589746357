import React from "react";

const Reload = (props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.05005 11C4.30293 9.07074 5.25027 7.29978 6.71473 6.01862C8.17918 4.73747 10.0604 4.03395 12.0061 4.03977C13.9519 4.04559 15.8288 4.76034 17.2856 6.05023C18.7423 7.34012 19.6791 9.11672 19.9204 11.0475C20.1618 12.9782 19.6912 14.9307 18.5967 16.5395C17.5023 18.1483 15.8591 19.3031 13.9746 19.7877C12.0902 20.2723 10.0937 20.0534 8.35892 19.1722C6.62417 18.2909 5.27005 16.8077 4.55005 15M4.05005 20V15H9.05005"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Reload;
