import React from "react";

const Cash = (props) => {
  return (
    <svg
      height="20"
      width="26"
      fill="none"
      viewBox="0 0 26 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.59995 0.4C1.96343 0.4 1.35298 0.652856 0.902895 1.10294C0.452808 1.55303 0.199951 2.16348 0.199951 2.8V14C0.199951 14.6365 0.452808 15.247 0.902895 15.6971C1.35298 16.1471 1.96343 16.4 2.59995 16.4H20.2C20.8365 16.4 21.4469 16.1471 21.897 15.6971C22.3471 15.247 22.6 14.6365 22.6 14V2.8C22.6 2.16348 22.3471 1.55303 21.897 1.10294C21.4469 0.652856 20.8365 0.4 20.2 0.4H2.59995ZM6.59995 2V3.6C6.59995 4.44869 6.26281 5.26263 5.66269 5.86274C5.06258 6.46286 4.24864 6.8 3.39995 6.8H1.79995V5.2H3.39995C3.8243 5.2 4.23126 5.03143 4.53132 4.73137C4.83138 4.43131 4.99995 4.02435 4.99995 3.6V2H6.59995ZM11.4 11.2C10.6573 11.2 9.94515 10.905 9.42005 10.3799C8.89495 9.8548 8.59995 9.14261 8.59995 8.4C8.59995 7.65739 8.89495 6.9452 9.42005 6.4201C9.94515 5.895 10.6573 5.6 11.4 5.6C12.1426 5.6 12.8547 5.895 13.3799 6.4201C13.905 6.9452 14.2 7.65739 14.2 8.4C14.2 9.14261 13.905 9.8548 13.3799 10.3799C12.8547 10.905 12.1426 11.2 11.4 11.2ZM1.79995 11.6V10H3.39995C4.24864 10 5.06258 10.3371 5.66269 10.9373C6.26281 11.5374 6.59995 12.3513 6.59995 13.2V14.8H4.99995V13.2C4.99995 12.7757 4.83138 12.3687 4.53132 12.0686C4.23126 11.7686 3.8243 11.6 3.39995 11.6H1.79995ZM19.4 11.6C18.9756 11.6 18.5686 11.7686 18.2686 12.0686C17.9685 12.3687 17.8 12.7757 17.8 13.2V14.8H16.2V13.2C16.2 12.3513 16.5371 11.5374 17.1372 10.9373C17.7373 10.3371 18.5513 10 19.4 10H21V11.6H19.4ZM19.4 5.2H21V6.8H19.4C18.5513 6.8 17.7373 6.46286 17.1372 5.86274C16.5371 5.26263 16.2 4.44869 16.2 3.6V2H17.8V3.6C17.8 4.02435 17.9685 4.43131 18.2686 4.73137C18.5686 5.03143 18.9756 5.2 19.4 5.2ZM24.2 14C24.2 15.0609 23.7785 16.0783 23.0284 16.8284C22.2782 17.5786 21.2608 18 20.2 18H3.53595C3.70146 18.4681 4.00808 18.8734 4.41357 19.16C4.81905 19.4465 5.30343 19.6003 5.79995 19.6H20.2C21.6852 19.6 23.1095 19.01 24.1597 17.9598C25.21 16.9096 25.8 15.4852 25.8 14V6C25.8002 5.50348 25.6465 5.0191 25.3599 4.61361C25.0733 4.20813 24.6681 3.90151 24.2 3.736V14Z"
        fill="#F23E53"
      />
    </svg>
  );
};

export default Cash;
