import React from "react";
import { ClipLoader } from "react-spinners";
import "./Loader.css";

const Loader = ({ loading, size = 50 }) => {
  return (
    <div className="loader-root">
      <ClipLoader color={"#E21730"} loading={loading} size={size} />
    </div>
  );
};

export default Loader;
