import React from "react";

const Star = (props) => {
  return (
    <svg
      height="16"
      width="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99989 11.8334L3.88522 13.9967L4.67122 9.41472L1.33789 6.17005L5.93789 5.50338L7.99522 1.33472L10.0526 5.50338L14.6526 6.17005L11.3192 9.41472L12.1052 13.9967L7.99989 11.8334Z"
        stroke="#FFBE16"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Star;
