import React from "react";

const MyTickets = ({ ticketsData }) => {
  return (
    <div>
      {ticketsData?.map((each) => (
        <div className="my_ticket_cont flex mb-10">
          <p className="label">Your Ticket No </p>
          <p className="ticket_number"> #{each?.ticket_number}</p>
        </div>
      ))}
    </div>
  );
};

export default MyTickets;
