import React from "react";

const Share = (props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18_584)">
        <path
          d="M0 12.0003C0 5.37232 5.37264 0.000106812 11.9999 0.000106812C18.6274 0.000106812 24 5.37232 24 12.0003C24 18.6273 18.6274 23.9998 11.9999 23.9998C5.37264 23.9998 0 18.6273 0 12.0003Z"
          fill="url(#paint0_linear_18_584)"
        />
        <path
          d="M7.08589 14.6458C8.52334 14.6458 9.68863 13.4805 9.68863 12.0431C9.68863 10.6056 8.52334 9.44032 7.08589 9.44032C5.64844 9.44032 4.48315 10.6056 4.48315 12.0431C4.48315 13.4805 5.64844 14.6458 7.08589 14.6458Z"
          fill="white"
        />
        <path
          d="M15.0314 10.019C16.4689 10.019 17.6342 8.85375 17.6342 7.4163C17.6342 5.97885 16.4689 4.81357 15.0314 4.81357C13.594 4.81357 12.4287 5.97885 12.4287 7.4163C12.4287 8.85375 13.594 10.019 15.0314 10.019Z"
          fill="white"
        />
        <path
          d="M15.0314 19.1864C16.4689 19.1864 17.6342 18.0212 17.6342 16.5837C17.6342 15.1463 16.4689 13.981 15.0314 13.981C13.594 13.981 12.4287 15.1463 12.4287 16.5837C12.4287 18.0212 13.594 19.1864 15.0314 19.1864Z"
          fill="white"
        />
        <path
          d="M6.73472 11.3499L7.43896 12.5593L15.3843 7.93297L14.6801 6.72349L6.73472 11.3499Z"
          fill="white"
        />
        <path
          d="M6.73407 12.5606L14.6794 17.187L15.3837 15.9775L7.43831 11.3511L6.73407 12.5606Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_18_584"
          gradientUnits="userSpaceOnUse"
          x1="12"
          x2="12"
          y1="0.000106812"
          y2="23.9998"
        >
          <stop stopColor="#EB1D36" />
          <stop offset="1" stopColor="#DA122A" />
        </linearGradient>
        <clipPath id="clip0_18_584">
          <rect height="24" width="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Share;
