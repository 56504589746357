import React from "react";

const Nonotification = (props) => {
  return (
    <svg
      height="244"
      width="271"
      fill="none"
      viewBox="0 0 271 244"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M235.163 37.8149C259.125 53.1219 267.308 86.6193 270.435 117.869C273.171 145.215 265.874 171.699 250.693 193.02C235.321 214.61 214.094 229.216 189.841 231.061C162.474 233.143 131.602 228.746 115.472 203.307C99.6057 178.282 109.012 144.874 116.31 115.087C123.231 86.8421 132.288 58.3096 154.544 43.8401C179.191 27.8157 210.411 22.0038 235.163 37.8149Z"
        fill="#FF8C9A"
        opacity="0.4"
      />
      <path
        d="M186.208 55.0334C216.457 70.0854 234.041 97.497 237.13 126.353C239.99 153.078 223.707 177.377 201.409 197.046C179.127 216.702 150.716 232.591 117.979 231.458C84.0822 230.283 55.3356 213.262 34.6347 191.27C12.0452 167.271 -7.24573 138.136 2.66719 108.951C12.8193 79.0616 47.0874 60.0269 82.7727 49.5439C116.985 39.4935 155.15 39.5787 186.208 55.0334Z"
        fill="#FF8C9A"
        opacity="0.4"
      />
      <g>
        <path
          d="M111.504 92.7391C101.862 96.7682 90.7405 92.2021 86.7114 82.56C82.6823 72.918 87.2484 61.7965 96.8905 57.7674C106.533 53.7383 117.654 58.3045 121.683 67.9465C125.712 77.5886 121.146 88.71 111.504 92.7391ZM101.762 69.4245C98.5512 70.7661 97.027 74.4785 98.3685 77.6889C99.71 80.8993 103.422 82.4235 106.633 81.082C109.843 79.7405 111.368 76.028 110.026 72.8176C108.684 69.6072 104.972 68.083 101.762 69.4245Z"
          fill="#EDFFFF"
        />
        <path
          d="M96.8925 57.7665L101.764 69.4247C104.974 68.0845 108.685 69.6083 110.026 72.8176C111.367 76.027 109.844 79.7378 106.634 81.08L111.506 92.7382C121.147 88.7079 125.712 77.5882 121.683 67.9465C117.654 58.3048 106.535 53.739 96.8925 57.7665Z"
          fill="#EDFFFF"
        />
        <path
          d="M155.704 198.999C149.568 201.564 142.494 198.659 139.93 192.523C139.416 191.293 139.995 189.882 141.225 189.368L159.026 181.93C160.256 181.416 161.667 181.995 162.181 183.225C164.745 189.361 161.84 196.435 155.704 198.999Z"
          fill="url(#paint0_linear_50_794)"
        />
        <path
          d="M192.674 136.078L180.808 107.68C168.482 78.1902 134.594 64.2769 105.102 76.5972C75.6125 88.9233 61.6993 122.811 74.0196 152.303L85.8863 180.701C81.0816 185.066 79.246 192.093 81.8904 198.427C85.2547 206.47 94.492 210.262 102.537 206.904L199.617 166.337C207.66 162.973 211.453 153.736 208.095 145.691C205.446 139.358 199.156 135.726 192.674 136.078Z"
          fill="url(#paint1_linear_50_794)"
        />
        <path
          d="M94.2961 138.829C99.1974 141.382 111.409 139.04 114.535 130.371"
          stroke="#F76066"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.40475"
        />
        <path
          d="M94.4325 139.155C100.069 142.551 111.743 141.124 114.672 130.698"
          stroke="#F76066"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.40475"
        />
        <path
          d="M139.072 119.351C143.973 121.905 156.185 119.562 159.311 110.894"
          stroke="#F76066"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.40475"
        />
        <path
          d="M139.208 119.678C144.845 123.074 156.519 121.647 159.448 111.22"
          stroke="#F76066"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.40475"
        />
        <path
          d="M189.515 56.0462L186.247 52.2684L182.86 18.9152L182.706 18.7368L169.455 30.2012L164.433 24.3963L186.623 5.19733L190.131 9.2516L193.595 42.0529L193.75 42.232L208.227 29.7065L213.25 35.5114L189.515 56.0462ZM179.608 82.3097L177.493 79.8653L175.302 58.2837L175.202 58.1682L166.628 65.5863L163.378 61.8302L177.737 49.4074L180.006 52.0307L182.248 73.255L182.348 73.3711L191.716 65.2662L194.966 69.0223L179.608 82.3097ZM171.219 103.708L169.681 101.93L168.087 86.2348L168.014 86.1508L161.779 91.5458L159.415 88.8141L169.858 79.7793L171.508 81.6872L173.139 97.1232L173.211 97.2074L180.024 91.313L182.388 94.0448L171.219 103.708Z"
          fill="url(#paint2_linear_50_794)"
        />
        <ellipse
          cx="139.002"
          cy="157.724"
          fill="#FE5A69"
          rx="7.07587"
          ry="8.49104"
          transform="rotate(-22.6785 139.002 157.724)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_50_794"
          gradientUnits="userSpaceOnUse"
          x1="150.125"
          x2="155.704"
          y1="185.649"
          y2="198.999"
        >
          <stop stopColor="#F23E53" />
          <stop offset="1" stopColor="#E9374C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_50_794"
          gradientUnits="userSpaceOnUse"
          x1="105.102"
          x2="151.077"
          y1="76.5972"
          y2="186.621"
        >
          <stop stopColor="#EDFFFF" />
          <stop offset="1" stopColor="#A6ECEC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_50_794"
          gradientUnits="userSpaceOnUse"
          x1="157.961"
          x2="200.109"
          y1="29.9962"
          y2="78.712"
        >
          <stop stopColor="#FF5064" />
          <stop offset="1" stopColor="#E33146" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Nonotification;
