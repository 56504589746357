import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Notifications from "../Pages/Notifications/Notifications";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import Missions from "../Pages/Missions/Missions";
import DailyRewards from "../Pages/DailyRewards/DailyRewards";
import MyWinningPrize from "../Pages/MyWinningPrize/MyWinningPrize";
import HowItWorks from "../Pages/HowItWorks/HowItWorks";
import LotteryDetails from "../Pages/LotteryDetails/LotteryDetails";
import MissionDetail from "../Pages/MissionDetail/MissionDetail";
import MyLevel from "../Pages/MyLevel/MyLevel";
import WalletInfo from "../Pages/WalletInfo/WalletInfo";
import UserPage from "../Pages/UserPage/UserPage";
import CashInfo from "../Pages/WalletInfo/CashInfo";
import WithdrawalList from "../Pages/WithdrawalList/WithdrawalList";
import ResultRefer from "../Pages/ResultsRefer/ResultRefer";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/profile" element={<UserPage />} />
      <Route path="/account-settings" element={<ProfilePage />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/missions" element={<Missions />} />
      <Route path="/daily-rewards" element={<DailyRewards />} />
      <Route path="/my-winnings" element={<MyWinningPrize />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/lottery/:id" element={<LotteryDetails />} />
      <Route path="/mission/:id" element={<MissionDetail />} />
      <Route path="/my-level" element={<MyLevel />} />
      <Route path="/wallet-info" element={<WalletInfo />} />
      <Route path="/cash-info" element={<CashInfo />} />
      <Route path="/withdrawal-list" element={<WithdrawalList/>} />
      <Route path="/result-refer" element={<ResultRefer/>} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default AdminRoutes;
