import React from "react";

const Accumulation = (props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12 21V21.01M3 9V9.01M21 9V9.01M8 20.1C6.63534 19.4344 5.46126 18.4343 4.58704 17.1929C3.71281 15.9515 3.16686 14.5091 3 13M16 20.1C17.3647 19.4344 18.5387 18.4343 19.413 17.1929C20.2872 15.9515 20.8331 14.5091 21 13M6.2 5C7.80809 3.68396 9.82204 2.96491 11.9 2.96491C13.978 2.96491 15.9919 3.68396 17.6 5"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Accumulation;
