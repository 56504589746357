import React from "react";

const ChevronDown = (props) => {
  return (
    <svg
      height="8"
      width="14"
      fill="none"
      viewBox="0 0 14 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ChevronDown;
