import React from "react";
import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
import "./WalletInfo.css";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import { last6monthsOptions } from "../../utils/utils";
import { useState } from "react";
import { useEffect } from "react";
import HistoryCard from "./HistoryCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchCashHistory } from "../../redux/WalletSlice";
import Loader from "../../components/Loader/Loader";
import WithdrawModal from "./WithdrawModal";
import { useNavigate } from "react-router-dom";

const CashInfo = () => {
  const [dropdownData, setDropdownData] = useState([]);
  const dropdownChangeHandler = (value) => {
    let params = {
      month: value?.month,
      year: value?.year,
    };
    dispatch(fetchCashHistory(params));
    console.log(value);
  };
  useEffect(() => {
    let monthFilterOptions = last6monthsOptions();
    let allOptions = { label: "All", type: "Year" };
    setDropdownData([allOptions, ...monthFilterOptions]);
  }, []);
  const { wallet } = useSelector((store) => store?.user || {});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCashHistory());
  }, []);

  const { cash_history: history, isLoading } = useSelector(
    (store) => store.wallet || {}
  );

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="page wallet_info">
      {showWithdrawModal && (
        <WithdrawModal
          show={showWithdrawModal}
          setShow={setShowWithdrawModal}
        />
      )}
      <Loader loading={isLoading} />
      <GoBackHeader
        goBackHandler={() => {
          navigate("/");
        }}
      />
      <div className="p-20">
        <div className="wallet_card">
          <p className="title">Total Cash Earned</p>
          <p className="points">INR {wallet?.total_wallet || 0}</p>
          <button onClick={() => setShowWithdrawModal(true)}>Withdraw</button>
        </div>
        <div className="affllate_history flex justify-between align-center">
          <p className="label">Cash History</p>
          <DropdownMenu
            onChange={dropdownChangeHandler}
            dropdownData={dropdownData}
          />
        </div>
        <div className="wallet_info_cards">
          {history?.map((each) => (
            <HistoryCard
              id={each?.id}
              date={each?.created_at || ""}
              title={each?.title || ""}
              isCredit={each.status === 1}
              points={`INR ${each?.amount}` || "0"}
            />
          ))}
          {!isLoading && !history?.length && (
            <p className="no_data">No data found...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CashInfo;
