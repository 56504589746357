import React from "react";

const SportsWeekly = (props) => {
  return (
    <svg
      height="42"
      width="42"
      fill="none"
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="42" width="42" fill="#FFDADE" rx="4" />
      <path
        d="M21 11C26.523 11 31 15.477 31 21C31 26.523 26.523 31 21 31C15.477 31 11 26.523 11 21C11 15.477 15.477 11 21 11ZM22.67 25H19.33L17.95 26.897L18.504 28.603C19.3097 28.8669 20.1522 29.0009 21 29C21.871 29 22.71 28.86 23.496 28.603L24.049 26.897L22.669 25H22.67ZM14.294 19.872L13.002 20.809L13 21C13 22.73 13.549 24.331 14.482 25.64H16.392L17.715 23.82L16.687 20.65L14.294 19.872ZM27.706 19.872L25.313 20.65L24.285 23.82L25.607 25.64H27.517C28.4838 24.2862 29.0024 22.6636 29 21L28.997 20.809L27.706 19.872ZM23.29 13.333L22 14.273V16.79L24.694 18.747L26.933 18.02L27.487 16.317C26.4546 14.8879 24.9798 13.839 23.291 13.333H23.29ZM18.708 13.333C17.019 13.8393 15.5442 14.8885 14.512 16.318L15.066 18.02L17.305 18.747L20 16.79V14.273L18.709 13.333H18.708Z"
        fill="#F23E53"
      />
    </svg>
  );
};

export default SportsWeekly;
