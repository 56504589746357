
const level1Referrals = 0;
const level2Referrals = 10;
const level3Referrals = 20;
const level4Referrals = 50;
const level5Referrals = 200;

export const myLevelData = [
    {
        title: "Level 1",
        referrals: level1Referrals,
    },
    {
        title: "Level 2",
        description: "10 Referals Needed",
        completed_msg: "Completed 10 Referrals",
        referrals: level2Referrals

    },
    {
        title: "Level 3",
        description: "Need 20 Referals to reach level 3",
        referrals: level3Referrals
    },
    {
        title: "Level 4",
        description: "Need 50 Referals to reach level 4",
        referrals: level4Referrals
    },
    {
        title: "Level 5",
        description: "Need 200 Referals to reach level 5",
        referrals: level5Referrals
    },
]