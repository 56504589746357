import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { signInUser, signupUser } from "../../redux/user/userSlice";
import { toast } from "react-toastify";
import TextField from "../../components/Input/TextField";
import logo from "../../assests/Images/bonus_logo.png";
import "./RegisterPage.css";
import Select from "react-select";
import { country_names } from "./data";
import { removeInvaildCharFromMobileField } from "../../utils/utils";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

const RegisterPage = () => {
  const [cookies, setCookie] = useCookies(["bjrefer"]);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const referalCode = params.get("referalCode");

  const setReferDoneCookie = () => {
    setCookie("bjrefer", "yes", { path: "/", maxAge: 24 * 60 * 60 * 365 });
  };

  const isReferDone = () => {
    return cookies?.bjrefer == "yes";
  };

  const [values, setValues] = useState({
    country_code: { label: "+91", value: "+91" },
  });
  const [errors, setErrors] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);

  useState(() => {
    let update = country_names?.map((each) => {
      let label = each.dial_code;
      return { label: label, value: label };
    });
    setCountryOptions(update);
  }, []);

  const cb = (success, res) => {
    if (success) {
      setReferDoneCookie();
      toast.success("Signup  Successfully");
    } else {
      if (res?.message) {
        setErrors(res?.message);
        return;
      }
      toast.error("Something went wrong, Try again");
    }
  };

  const dispatch = useDispatch();

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let payload = { ...values };
    if (payload.password !== payload.c_password) {
      setErrors({
        ...errors,
        c_password: ["Password and Confirm password not matched"],
      });
      return;
    }
    payload.phone = parseInt(values.phone);
    payload.country_code = values?.country_code?.value;
    dispatch(
      signupUser({
        payload: payload,
        cb: cb,
        url: "/register",
      })
    );
  };

  console.log(values,'hd')

  const { isLoading } = useSelector((store) => store.user);

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "phone") {
      value = removeInvaildCharFromMobileField(value);
      if (value?.length > 13) return;
    }
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    if (errors?.c_password && name === "c_password") {
      if (values.password == value) {
        setErrors({
          ...errors,
          c_password: null,
        });
      } else {
        setErrors({
          ...errors,
          c_password: ["Password and Confirm password not matched"],
        });
      }
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (referalCode && !isReferDone()) {
      setValues({ ...values, referal_code: referalCode });
    }
  }, [referalCode]);


  return (
    <>
      <Loader loading={isLoading} />
      <div className="register-page">
        <div className="logo-cont ">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <p className="section-title text-center mt-15">Sign Up</p>
        </div>

        <div className="bottom-cont mt-20">
          <form onSubmit={formSubmitHandler}>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.full_name ? " error" : ""}`}
                name="name"
                label="Full Name"
                type="text"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.full_name?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.email ? " error" : ""}`}
                name="email"
                label="Email"
                type="email"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.email?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.user_name ? " error" : ""}`}
                name="username"
                label="Username"
                type="text"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.username?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.password ? " error" : ""}`}
                name="password"
                label="Password"
                type="password"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.password ? " error" : ""}`}
                name="c_password"
                label="Confirm Password"
                type="password"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.c_password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <div className="flex country-input-cont">
                <div className="country-field">
                  <Select
                    classNamePrefix={"country-field"}
                    value={values?.country_code}
                    options={countryOptions}
                    onChange={(val) => {
                      setValues({ ...values, country_code: val });
                    }}
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    className={` ${errors?.phone ? " error" : ""}`}
                    name="phone"
                    label="Phone"
                    type="text"
                    setValues={setValues}
                    values={values}
                    required
                    onChange={inputChangeHandler}
                  />
                </div>
              </div>
              {errors?.phone?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
              {errors?.country_code?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <button className="btn btn-primary">Register</button>
          </form>
          <div className=" sign-up-cont ">
            <div className=" p-cont flex align-center justify-center">
              <p className="mr-5 text-light">Don't Have Account?</p>
              <Link className="f-15 w-600" to={`/signin`}>
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
