import React from "react";

const Link = (props) => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" fill="#F7F7F7" r="12" />
      <path
        d="M10.5 13.5C10.7444 13.7494 11.0361 13.9476 11.3581 14.0829C11.6801 14.2182 12.0258 14.2879 12.375 14.2879C12.7242 14.2879 13.0699 14.2182 13.3919 14.0829C13.7139 13.9476 14.0056 13.7494 14.25 13.5L17.25 10.5C17.7473 10.0027 18.0267 9.32824 18.0267 8.62498C18.0267 7.92172 17.7473 7.24726 17.25 6.74998C16.7527 6.2527 16.0783 5.97333 15.375 5.97333C14.6717 5.97333 13.9973 6.2527 13.5 6.74998L13.125 7.12498"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M13.5 10.5C13.2556 10.2506 12.9639 10.0524 12.6419 9.91709C12.3199 9.7818 11.9742 9.71212 11.625 9.71212C11.2758 9.71212 10.9301 9.7818 10.6081 9.91709C10.2861 10.0524 9.99441 10.2506 9.75 10.5L6.75 13.5C6.25272 13.9973 5.97335 14.6717 5.97335 15.375C5.97335 16.0783 6.25272 16.7527 6.75 17.25C7.24728 17.7473 7.92174 18.0267 8.625 18.0267C9.32826 18.0267 10.0027 17.7473 10.5 17.25L10.875 16.875M15 18.75V17.25M17.25 15H18.75M5.25 9H6.75M9 5.25V6.75"
        stroke="#F23E53"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Link;
