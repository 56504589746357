import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
const initialState = {
    data: null,
    isLoading: false,
}

export const fetchMissions = createAsyncThunk('/api/mission', (id, { rejectWithValue }) => {
    return axios.get(`/mission`).then(res => res.data)
});

export const fetchMissionSlice = createSlice({
    name: 'notification',
    initialState,
    extraReducers: {
        [fetchMissions.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchMissions.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.data = action?.payload?.data;
        },
        [fetchMissions.rejected]: (state) => {
            state.isLoading = false;
        },
    },
})

export default fetchMissionSlice.reducer;

